<template>
  <UiSidePanel v-model="vModel">
    <template #default>
      <div v-if="activityToPreview">
        <!-- Header -->
        <div
          class="flex flex-col items-start justify-center gap-3 border-b border-solid border-black-10 px-8 pb-4 pt-10"
        >
          <div class="flex w-full flex-row items-center justify-between">
            <div class="flex flex-row items-center gap-2">
              <UiInputCheckbox
                :model-value="activityToPreview?.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE"
                :name="activityToPreview?.title as string"
                round
                :read-only="
                  isDoneActivity ||
                  (activityToPreview?.user_id !== user?.id &&
                    !useHasPermissions([PERMISSIONS.CLOSE_ACTIVITIES_ON_BEHALF_OF_THE_USER]))
                "
                border-color-class="border-black-30"
                @click.stop
                @update:model-value="updateActivityStatus()"
              />
              <UiTagPrimary
                :id="`type_${activityToPreview?.type?.code}`"
                class="!w-fit"
                :style="{
                  backgroundColor: CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(activityToPreview.type?.code)?.background,
                }"
                right-icon=""
              >
                <div class="text-subhead-4 text-black-100">{{ activityToPreview.type?.name }}</div>
              </UiTagPrimary>
            </div>
            <div v-if="!isManagerPreview" class="flex flex-row items-center gap-4">
              <UiButtonGhost
                v-if="activityToPreview?.status?.code !== CALENDAR_ACTIVITY_STATUSES.DONE"
                id="edit-activity"
                class="!text-black-60"
                @click="$emit('edit', activity)"
              >
                <UiIcon name="edit"></UiIcon>
              </UiButtonGhost>
              <UiButtonGhost id="delete-activity" class="!text-black-60" @click="$emit('delete', activity)">
                <UiIcon name="trash"></UiIcon>
              </UiButtonGhost>
            </div>
          </div>
          <h4>
            {{ activityToPreview.title }}
          </h4>
        </div>
        <!-- Body -->
        <div class="flex flex-col gap-4 px-8 py-4">
          <div class="flex flex-row items-center gap-4 py-2.5">
            <div class="w-6">
              <UiIcon name="clock" class="text-black-70" />
            </div>
            <div class="text-body-2 px-2">
              {{ formattedActivityDate }}
            </div>
          </div>
          <transition name="fade" mode="out-in">
            <keep-alive>
              <component
                :is="currentComponent"
                v-if="activityToPreview?.status?.code !== CALENDAR_ACTIVITY_STATUSES.DONE && !isManagerPreview"
                :model-value="activityToPreview"
              ></component>
            </keep-alive>
          </transition>
          <div
            v-if="
              activityToPreview.comment &&
              activityToPreview?.status?.code !== CALENDAR_ACTIVITY_STATUSES.DONE &&
              !isManagerPreview
            "
            class="flex flex-row items-start gap-6"
          >
            <div class="w-6">
              <UiIcon name="text-align-left" class="text-black-70" />
            </div>
            <div class="text-body-2">
              {{ activityToPreview.comment }}
            </div>
          </div>
          <div
            v-if="
              activityToPreview?.notifications?.length &&
              activityToPreview?.status?.code !== CALENDAR_ACTIVITY_STATUSES.DONE &&
              !isManagerPreview
            "
            class="flex flex-row items-start gap-6"
          >
            <div class="w-6">
              <UiIcon name="notification" class="text-black-70" />
            </div>
            <div class="text-body-2">
              Reminders:
              <div v-for="(item, index) in activityToPreview.notifications" :key="index">
                <div v-if="item.hasOwnProperty('sms')">
                  <span class="text-subhead-4">SMS </span>
                  <span class="text-body-2">in {{ item.sms }} minutes</span>
                </div>
                <div v-if="item.hasOwnProperty('popup')">
                  <span class="text-subhead-4">Notification </span>
                  <span class="text-body-2">in {{ item.popup }} minutes</span>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t border-solid border-black-10"></div>
          <div v-if="activityToPreview.creator_user" class="flex flex-row items-center gap-6">
            <UiIcon name="user-circle" class="text-black-70" />
            <div>
              <span class="text-subhead-4 text-black-40">Creator name: </span>
              <span class="text-body-2">{{ activityToPreview.creator_user?.name }}</span>
            </div>
          </div>
          <div class="flex flex-row items-center gap-6">
            <UiIcon name="user" class="text-black-70" />
            <div>
              <span class="text-subhead-4 text-black-40">Agent name: </span>
              <span class="text-body-2">{{ activityToPreview.user?.name }}</span>
            </div>
          </div>
          <div class="flex flex-row items-center gap-6">
            <UiIcon name="link" class="text-black-70" />
            <div>
              <span class="text-subhead-4 text-black-40">Lead name: </span>
              <span
                class="text-subhead-4 cursor-pointer underline"
                @click="
                  navigateTo(`/leads/${activityToPreview.lead_id}`, {
                    open: {
                      target: '_blank',
                    },
                  })
                "
                >{{ activityToPreview.lead?.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </UiSidePanel>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { useAuthStore } from '@/store/auth'
import { useUiStore } from '@/store/ui'
import {
  CALENDAR_ACTIVITY_STATUSES,
  CALENDAR_ACTIVITY_TYPES,
  CALENDAR_ACTIVITY_TYPES_COLORS_MAP,
  PERMISSIONS,
} from '@/constants'
import type { Activity, LibraryItem } from '@/types'
import { POPUPS } from '@/components/dynamic/maps'

const emits = defineEmits(['update:modelValue', 'edit', 'delete', 'update:status'])

const authStore = useAuthStore()
const user = useUser()
const uiStore = useUiStore()

type Props = {
  modelValue: boolean
  activity: Activity
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  activity: undefined,
})

const vModel = useVModel(props, 'modelValue', emits)

onNuxtReady(async () => await Promise.all([getCalendarTypes(), getCalendarActivityStatuses()]))

const eventTypes = ref<LibraryItem[]>([])
const eventStatuses = ref<LibraryItem[]>([])
const activityToPreview = ref<Activity | undefined>(
  props.activity
    ? {
        ...toRaw(props.activity),
        type_id: Number(props.activity.type?.id),
        lead_id: props.activity.lead?.id,
        user_id: Number(props.activity.user?.id),
      }
    : undefined
)
const isManagerPreview = computed(
  () => activityToPreview.value?.user_id !== user?.id && (authStore.getIsManager || authStore.getIsLeadManager)
)
const isAllDayType = computed(() => activityToPreview.value?.type?.code === CALENDAR_ACTIVITY_TYPES.ALL_DAY)
const isDoneActivity = computed(() => props.activity.status?.code === CALENDAR_ACTIVITY_STATUSES.DONE)

const formattedActivityDate = computed(() => {
  if (!activityToPreview.value) return
  if (isAllDayType.value) return format(new Date(activityToPreview.value?.start), 'MMM dd, yyyy')
  return (
    format(new Date(activityToPreview.value.start), 'MMM dd, yyyy, kk:mm') +
    ' - ' +
    format(new Date(activityToPreview.value.end), 'kk:mm')
  )
})

const getCalendarTypes = async () => {
  eventTypes.value = await useCalendarActivityTypes()
  if (activityToPreview.value && !activityToPreview.value?.type_id) {
    activityToPreview.value.type_id = eventTypes.value[0].id
    activityToPreview.value.type = eventTypes.value[0]
  }
}

const getCalendarActivityStatuses = async () => {
  eventStatuses.value = await useCalendarActivityStatuses()
}

const eventTypeComponent = {
  [CALENDAR_ACTIVITY_TYPES.MEETING]: resolveComponent('ActivityPreviewMeeting'),
  [CALENDAR_ACTIVITY_TYPES.CALL]: resolveComponent('ActivityPreviewCall'),
}

const currentComponent = computed(
  () =>
    eventTypeComponent[
      eventTypes.value.find((t) => t.id === activityToPreview.value?.type_id)?.code as keyof typeof eventTypeComponent
    ]
)

const updateActivityStatus = async () => {
  if (
    activityToPreview.value?.type?.code === CALENDAR_ACTIVITY_TYPES.MEETING &&
    !activityToPreview.value.meeting_outcome_resolution_id
  ) {
    uiStore.showPopup(
      POPUPS.MEETING_OUTCOME,
      { activity: activityToPreview },
      { input: (outcomeId: number) => (activityToPreview.value!.meeting_outcome_resolution_id = outcomeId) }
    )
  } else {
    const statusCode = CALENDAR_ACTIVITY_STATUSES.DONE

    const status = eventStatuses.value.find((s) => s.code === statusCode)
    if (!status) return
    try {
      await useUpdateCalendarActivityStatus(Number(activityToPreview.value?.id), status.id)
      emits('update:status', status)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  }
}
</script>

<style scoped></style>
